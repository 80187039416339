<template>
  <v-container class="">
    <h1 v-if="title" class="mb-4"> {{title}} </h1>
    <section v-if="$slots['content']">
      <div v-if="isLoading" class="d-flex justify-center">
        <PageLoader class="mt-15"/>
      </div>
      <slot v-else name="content"></slot>
    </section>
  </v-container>
</template>
<script>

import PageLoader from "@/components/PageLoader"

export default {
  name: 'PageViewTemplate',
  props: {
    pageTitle: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false
    },
  },
  components: {
    PageLoader
  },
  computed: {
    title() {
      return this.pageTitle ?? this.$route.meta.pageTitle ?? ""
    },
  }

}
</script>
