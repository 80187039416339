<template>
    <div class="page-loader__loader"/>
</template>

<script>

export default {
  name: 'CdPageLoader',
}
</script>
