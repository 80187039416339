<template>
  <page-view>
    <section slot="content">
      <v-row class="mt-10">
        <v-col lg="4" md="6" offset-md="3" offset-lg="4">
          К сожалению, запрашиваемая вами страница не найдена
        </v-col>
      </v-row>
    </section>
  </page-view>
</template>

<script>
import PageView from "@/layouts/components/PageView"

export default {
  name: 'NotFound',
  components: {
    'page-view': PageView
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
